import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../../../src/components/modules/Layout"

import ProductHeader from "../../../src/components/blocks/ProductHeader"
import ProductTabsBlock from "../../../src/components/blocks/ProductTabsBlock"
import VariantCardsBlock from "../../../src/components/blocks/VariantCardsBlock"

import Seo from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Seo"

import CrossSellingVariantsCardsBlock from "../../../src/components/blocks/CrossSellingVariantsCardsBlock"

const VariantTemplate = ({ data }) => {
  const entry = data.entry
  const group = data.group
  const line = data.line
  const area = data.area
  const sbPage = data.storyblokPage
  const sbPageContent =
    sbPage && sbPage.content ? JSON.parse(sbPage.content) : null
  const crossSellingVariants = data.crossSellingVariants.edges
  const deliveryScope = null
  //   entry.properties.deliveryScope !== "undefined"
  //     ? entry.properties.deliveryScope
  //     : entry.properties.salesText

  let breadcrumbs = [
    { link: entry.rootPage.fullSlug, link_text: entry.rootPage.name },
    { link: area.fullSlug, link_text: area.name },
    { link: line.fullSlug, link_text: line.name },
    { link: group.fullSlug, link_text: group.name },
  ]

  const variants = data.variants.edges
  const description = entry.marketingText?.value
  const features = entry.benefitsText?.value
  const techData = entry.technicalData
  const generalData = entry.generalData
  const documents = entry.productManuals?.items || null
  const safetyDatasheets = entry.productCeCertificationDocuments?.items || null
  //const safetyDatasheets = JSON.parse(entry.properties.safetyDatasheets)
  const crossSellingItems = crossSellingVariants?.map((v) => {
    return { label: v.node.name, link: v.node.fullSlug }
  })
  // const media = JSON.parse(entry.properties.media)

  const variantItems = variants
    .map((variant) => {
      return {
        active: entry.ID === variant.node.ID,
        slug: variant.node.fullSlug,
        headline: variant.node.name,
        text: variant.node.articleNumber?.value,
        image: variant.node.mainImage?.value || undefined,
        accessories: "TBD",
      }
    })
    // active item is always the first item
    .sort((x, y) => {
      return x.active === y.active ? 0 : x.active ? -1 : 1
    })

  return (
    <Layout language={entry.language}>
      <Seo
        title={`${entry.name} | ${group.name}`}
        description={`${
          entry.properties?.description?.replace(/\n/, "") || ""
        }`}
        og_image={entry.mainImage?.value}
        language={entry.language}
      />
      <ProductHeader
        block={{ headline: entry.name, _uid: entry.ID }}
        breadcrumbs={breadcrumbs}
      />

      <ProductTabsBlock
        entry={entry}
        description={description}
        variantItems={variantItems}
        features={features}
        deliveryScope={deliveryScope}
        crossSellingItems={crossSellingItems}
        documents={documents}
        safetyDatasheets={safetyDatasheets}
        techData={techData}
        generalData={generalData}
      />

      {variantItems?.length > 1 && (
        <VariantCardsBlock
          block={{
            variant_items: variantItems,
          }}
        />
      )}
    </Layout>
  )
}

export const query = graphql`
  query VariantQuery(
    $id: String!
    $parentId: String!
    $language: String
    $groupId: String
    $lineId: String
    $areaId: String
    $crossSellingVariantIDs: [String]
  ) {
    entry: contentServVariant(ID: { eq: $id }, language: { eq: $language }) {
      name
      externalKey
      ID
      language
      rootPage {
        name
        fullSlug
      }
      marketingText {
        value
      }
      benefitsText {
        value
      }
      articleNumber {
        value
      }
      mainImage {
        value
      }
      productImages {
        items {
          value
        }
      }
      productVideos {
        items {
          label
          videoLangKey
        }
      }
      generalData {
        id
        value
        label
      }
      productManuals {
        items {
          value
          label
        }
      }
      productCeCertificationDocuments {
        items {
          value
          label
        }
      }
      productDatasheet {
        value
        label
      }
      productSparepartLists {
        items {
          value
          label
        }
      }
      productSecurityDatasheetsES {
        items {
          value
          label
        }
      }
      productSecurityDatasheetsPT {
        items {
          value
          label
        }
      }
      technicalData {
        id
        label
        value
      }
    }
    group: contentServProductGroup(
      ID: { eq: $groupId }
      language: { eq: $language }
    ) {
      name
      fullSlug
    }
    line: contentServProductLine(
      ID: { eq: $lineId }
      language: { eq: $language }
    ) {
      name
      fullSlug
    }
    area: contentServProductArea(
      ID: { eq: $areaId }
      language: { eq: $language }
    ) {
      name
      fullSlug
    }
    variants: allContentServVariant(
      filter: { parentId: { eq: $parentId }, language: { eq: $language } }
    ) {
      edges {
        node {
          name
          fullSlug
          ID
          parentId
          externalKey
          articleNumber {
            value
          }
          mainImage {
            value
          }
        }
      }
    }
    crossSellingVariants: allContentServVariant(
      filter: {
        externalKey: { in: $crossSellingVariantIDs }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          name
          fullSlug
        }
      }
    }
  }
`

export default VariantTemplate
